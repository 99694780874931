@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Roboto:wght@300;400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root 
.app {
    height: 100%;
}

.app-container {
    position: relative;
}

*::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    background-color: #ede9e9;
}  

*::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #a7a7a788;
}

@keyframes fade-increase {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }

}

.fadeIncrease {
    animation-duration: 0.4s;
    animation-name: fade-increase;
}